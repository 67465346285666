import { useEffect, useState } from "react";
import NaseUsluge from "./NaseUsluge";
import UslugeBackground from "../../../Images/galerija-auta/galerija-auta6.jpg";
import Main from "../Main/Main";

export default function Usluge() {
  useEffect(() => {
    document.title = "Autoconnect | Usluge";
  }, []);
  return (
    <>
      <Main imageUrl={UslugeBackground} altText="Usluge" />
      <NaseUsluge />
      <UslugeForm />
    </>
  );
}

function UslugeForm() {
  const [isMessageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Your existing form data
    const formData = new FormData(event.target);

    try {
      // Submit the form data to the web3forms API
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      // Check if the form submission was successful
      if (response.ok) {
        setMessageSent(true);
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      setError("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    const form = document.getElementById("form");
    const dateInput = document.getElementById("date");

    const handleFormSubmit = (e) => {
      const hCaptcha = form.querySelector(
        "textarea[name=h-captcha-response]"
      ).value;

      if (!hCaptcha) {
        e.preventDefault();
        alert("Please fill out the captcha field");
        return;
      }
    };

    const handleDateInputChange = () => {
      const selectedDate = new Date(dateInput.value);
      const selectedDay = selectedDate.getDay();
      const errorMessageSpan = document.getElementById("error-message");

      if (selectedDay === 0 || selectedDay === 6) {
        errorMessageSpan.textContent =
          "Odaberite samo radne dane u tjednu (Pon-Pet)";
        dateInput.value = formatDate(new Date());
      } else {
        errorMessageSpan.textContent = "";
      }
    };

    form.addEventListener("submit", handleFormSubmit);

    dateInput.min = formatDate(new Date());
    dateInput.value = formatDate(new Date());
    dateInput.addEventListener("input", handleDateInputChange);

    // Cleanup event listeners on component unmount
    return () => {
      form.removeEventListener("submit", handleFormSubmit);
      dateInput.removeEventListener("input", handleDateInputChange);
    };
  }, []);

  // Function to format the date as 'YYYY-MM-DD' in form
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <section id="section-form" className="section">
        <h4>REZERVIRAJTE TERMIN:</h4>
        {!isMessageSent ? (
          <form onSubmit={handleSubmit} className="contact-form" id="form">
            {/* <!-- Key --> */}
            <input
              type="hidden"
              name="access_key"
              value="ad815d2a-19f1-4774-9798-5d3dd2b1be90"
            />
            {/* <!-- From name --> */}
            <input
              type="hidden"
              name="from_name"
              defaultValue="Usluge AutoConnect"
            />
            {/*  <!-- Subject name --> */}
            <input
              type="hidden"
              name="subject"
              defaultValue="Nova rezervacija"
            />
            {/*  <!--  --> */}

            <label htmlFor="name">Ime i prezime:</label>
            <input
              type="text"
              name="Ime i Prezime"
              placeholder="Ime i Prezime"
              id="name"
              autoComplete="name"
            />
            <label htmlFor="email">
              Email: <span>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="primjer@gmail.com"
              required
            />
            <label htmlFor="phone">Mobitel:</label>
            <input
              type="tel"
              name="Broj mobitela"
              id="phone"
              placeholder="+385 1 234 5678"
              autoComplete="phone"
            />

            <label htmlFor="option">
              Odaberite uslugu:<span>*</span>
            </label>
            <select defaultValue="option" name="Usluga" id="option">
              <option defaultValue="Odaberite" disabled selected>
                Odaberite:
              </option>
              <option defaultValue="Sound&nbsp;modul" name="sound&nbsp;modul">
                Sound modul
              </option>
              <option defaultValue="Tapeciranje" name="Tapeciranje">
                Tapeciranje
              </option>
              <option defaultValue="Wrap" name="wrap">
                Wrap
              </option>
              <option
                defaultValue="Ugradnja&nbsp;felgi"
                name="Ugradnja&nbsp;felgi"
              >
                Ugradnja felgi
              </option>
              <option
                defaultValue="Pojasevi&nbsp;u&nbsp;boji"
                name="Pojasevi&nbsp;u&nbsp;boji"
              >
                Pojasevi u boji
              </option>
              <option
                defaultValue="Bojanja&nbsp;čeljusti"
                name="Bojanja&nbsp;čeljusti"
              >
                Bojanja čeljusti
              </option>
              <option
                defaultValue="Zatamnjivanje&nbsp;stakla"
                name="Zatamnjivanje&nbsp;stakla"
              >
                Zatamnjivanje stakla
              </option>
              <option defaultValue="ostalo" name="Ostalo">
                Ostalo
              </option>
            </select>
            <label htmlFor="car">
              Vozilo/model <span>*</span>
            </label>
            <input
              type="text"
              name="Vozilo/model"
              id="car"
              placeholder="Vozilo / model"
              required
            />
            <label htmlFor="date">Odaberite datum:</label>
            <input type="date" id="date" name="Datum" />
            <span id="error-message"></span>
            <label htmlFor="message">Pošaljite poruku:</label>
            <textarea
              name="Poruka"
              id="message"
              placeholder="Vaša poruka..."
            ></textarea>

            {/* <!-- Honeypot --> */}
            <input
              type="checkbox"
              name="botcheck"
              className="hidden"
              style={{ display: "none" }}
            />
            <input type="submit" defaultValue="POŠALJI" id="submit-btn" />
          </form>
        ) : (
          <div className="thank-you">
            <h1>
              PORUKA POSLANA! <i className="fi fi-ss-check-circle"></i>
            </h1>
            <p>Kontaktirati ćemo Vas u najkraćem mogućem roku!</p>
          </div>
        )}
        {error && (
          <div className="error-box">
            <h1>
              DOŠLO JE DO POGREŠKE! <i class="fi fi-ss-exclamation"></i>
            </h1>
            <p>Pokušajte ponovo kasnije ili nas nazovite </p>
            <p>
              <a href="tel:+385 1 2911 500">
                <p>
                  <i className="fi fi-ss-phone-call"></i> +385 1 2911 500
                </p>
              </a>
            </p>
          </div>
        )}
      </section>
    </>
  );
}
