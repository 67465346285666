// Images
import SoundBackground from "../../../Images/ostalo/Sound-modul-bg.jpg";
import Panthera1 from "../../../Images/usluge/Panthera.JPG";
import Panthera2 from "../../../Images/ostalo/Panthera-sound-modul1.JPG";
import PantheraVideo1Poster from "../../../Images/ostalo/Panthera-Video-Poster.jpg";
// Videos
import PantheraVideo1 from "../../../Images/videos/Panthera-Leo-Active-Sound-New-C-class-W206.mp4";
import SoundModulVideo1 from "../../../Images/videos/Sound-modul-video.mp4";

// React
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Main from "../Main/Main";
import { useState } from "react";

export default function SoundModul() {
  return (
    <>
      <Main imageUrl={SoundBackground} altText="Background Sound modul" />
      <SoundModulSection />
      <div className="border"></div>
      <SoundModulInstalacija />
      <div className="border"></div>
      <SoundModulKonfiguracija />
    </>
  );
}

function SoundModulSection() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };

  return (
    <>
      <section className="section" id="sound-modul">
        <h2>SOUND MODUL</h2>
        <p>
          Želite li svom vozilu pružiti više osjećaja, ili Vaš standardni
          ispušni sustav ne proizvode zvuk koji želite?
        </p>
        <p>
          Vozite li električno ili hibridno vozilo i možda uopće nemate buke u
          vožnji?
        </p>
        <p>Naš distributer Panthera za Vas ima riješenje.</p>
        <div className="sound-panthera-box">
          <h5>Preuzmite Panthera aplikaciju:</h5>
          <div>
            <Link
              className="panthera-app-btn"
              to="https://apps.apple.com/us/app/panthera-connect-6-0/id1469910456"
            >
              <i className="fi fi-brands-apple"></i>na App Store
            </Link>
            <Link
              className="panthera-app-btn"
              to="https://play.google.com/store/apps/details?id=de.panthera.connect&pcampaignid=web_share"
            >
              <i className="fi fi-brands-android"></i>na Google Play
            </Link>
          </div>
        </div>
        <div className="sound-grid">
          <img
            className="sound-images"
            src={Panthera1}
            alt="Sound modul"
            onClick={() => openImage(Panthera1)}
          />
          <img
            className="sound-images"
            src={Panthera2}
            alt="Sound modul"
            onClick={() => openImage(Panthera2)}
          />
        </div>
        <p>
          Pomoću aplikacija za pametni telefon Panthera Connect možete
          konfigurirati Leo Active Sound System prema Vašim željama.
        </p>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

function SoundModulInstalacija() {
  return (
    <>
      <section className="section">
        <h2>INSTALACIJA</h2>
        <p>
          Zahvaljujući našem OBD rješenju, montaža je brza i jednostavna.
          Zvučnik aktivnog zvučnog sustava može se ugraditi u vozilo, kao i
          vani, po želji. Po potrebi se mogu instalirati do četiri zvučnika.
        </p>
        <p>
          Snaga se napaja preko paljenja plus i trajnog plusa putem isporučenog
          adaptera (plug and play) na kutiji s osiguračima u vozilu.
        </p>
        <p>
          Tada se OBD-II adapter uključuje u vozilo. Ostali sustav radiji
          bežično.
        </p>
        <div className="sound-grid">
          <video
            className="sound-modul-video"
            controls
            disablePictureInPicture
            controlsList="nodownload"
            poster={PantheraVideo1Poster}
          >
            <source src={PantheraVideo1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video
            className="sound-modul-video"
            controls
            disablePictureInPicture
            controlsList="nodownload"
          >
            <source src={SoundModulVideo1} />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="usluge-btn">
          <HashLink smooth to="/usluge/#section-form">
            Naruči se &rarr;
          </HashLink>
        </div>
      </section>
    </>
  );
}

function SoundModulKonfiguracija() {
  return (
    <>
      <section className="section">
        <h2>KONFIGURACIJA</h2>
        <p>
          Leo Active Sound System isporučuje se unaprijed konfiguriran sa
          standardnim postavkama i stoga je širok za upotrebu.
        </p>
        <p>
          Za individualno zvučno iskustvo preporučujemo postavljanje pomoću
          aplikacija Panthera Connect 4.0.
        </p>
        <p>
          Ovdje možete prilagoditi 8 različitih zvučnih profila i odabrati
          zvučne datoteke.
        </p>
        <p>
          Glasnoća, brzina, prazan hod, prekidi paljenja – sve se može
          jednostavno i jasno postaviti pomoću aplikacija Panthera Connect 6.0.
        </p>
        <p>
          Uz to, uvijek ostanite u toku i redovito se ažurirajte. Ažuriranje se
          jednostavno instalira putem aplikacija.
        </p>
      </section>
    </>
  );
}
