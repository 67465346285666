import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";

// Images
import Techart1 from "../../../Images/zastupstva/techart/Techart.jpg";
import Techart2 from "../../../Images/zastupstva/techart/Techart2.jpg";
import Techart3 from "../../../Images/zastupstva/techart/Techart3.jpg";
import Techart4 from "../../../Images/zastupstva/techart/Techart4.jpg";
import Techart5 from "../../../Images/zastupstva/techart/Techart5.png";

export default function Hamann() {
  useEffect(() => {
    document.title = "Autoconnect | Techart";
  }, []);
  return (
    <>
      <Main imageUrl={Techart1} altText="Brabus" />
      <TechartText />
    </>
  );
}

function TechartText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="section">
        <h2>TECHART</h2>
        <div className="zastupstva-text">
          <p>
            Dobrodošli u svijet premium prilagodbi za vaš Porsche model.
            Vrhunska prilagodba za vaš Porscheov model. Od 1987. god.
          </p>
          <div className="zastupstva-grid">
            <div>
              <img
                src={Techart2}
                alt="Techart"
                onClick={() => openImage(Techart2)}
              />
              <img
                src={Techart3}
                alt="Techart"
                onClick={() => openImage(Techart3)}
              />
            </div>
          </div>
          <p>
            TECHART je međunarodna premium marka za individualnu prilagodbu
            svakog modela Porschea. Potaknuti posebnom potražnjom za izvrsnim
            dizajnom i najvišom razinom razvojne stručnosti. I posljednje, ali
            ne najmanje važno, odgovornim rukovanjem osjećajima i razumom. Za
            trajno uživanje u više individualnosti.
          </p>

          <p>
            Naše osnovno načelo: OEM kvaliteta izrađena u Njemačkoj – vidljiva i
            nevidljiva. Svaki pravi TECHART proizvod rezultat je ove maksime.
          </p>
          <div className="border"></div>
          <ul className="zastupstva-links">
            <li>
              <span>&#x2022;</span> Vlastiti razvoj dizajna i izrada modela na
              lokaciji Leonberg
            </li>
            <li>
              <span>&#x2022;</span> Aerodinamičke probne vožnje i optimizacija u
              zračnom tunelu
            </li>
            <li>
              <span>&#x2022;</span> CAD-podržani razvojni proces
            </li>
            <li>
              <span>&#x2022;</span> Precizna izrada alata za serijsku
              proizvodnju u poliuretanu-RIM i ugljiku
            </li>
            <li>
              <span>&#x2022;</span> Odobrenja tipa, certifikati dijelova i
              homologacija
            </li>
            <li>
              <span>&#x2022;</span> Provjera i ovjera u ispitivanjima sudara i
              opterećenja materijala
            </li>
            <li>
              <span>&#x2022;</span> Ugradnja i servisiranje bez problema
            </li>
            <li>
              <span>&#x2022;</span> Komponentni i vozački ispiti za maksimalne
              performanse i prikladnost za svakodnevnu upotrebu
            </li>
            <li>
              <span>&#x2022;</span> Kvalitetne sirovine kao što su PU-RIM,
              ugljična vlakna, ručno ubrana koža i dragocjeno drvo
            </li>
          </ul>
        </div>

        <div className="zastupstva-text">
          <h5>TECHART je sloboda</h5>
          <p>
            Svatko ima svoj pogled na stvari. Vlastite vještine i talenti.
            Vlastiti način razmišljanja i življenja. Vaša vlastita percepcija
            slobode i individualnosti. – Dakle, ako odlučite dio svoje osobnosti
            prenijeti na svoj Porscheov model, što bi za vas bila prava
            individualnost? Koja bi bila vaša mjera jedinstvenosti?
          </p>
          <div className="zastupstva-grid">
            <img
              src={Techart4}
              alt="Techart"
              onClick={() => openImage(Techart4)}
            />

            <p>
              Uživajte u slobodi naglašavanja upravo onih karakternih osobina u
              vašem vozilu koje za vas znače individualnost i jedinstvenost. S
              TECHART-om imate na raspolaganju gotovo neograničen raspon
              mogućnosti.
            </p>
          </div>
          <div className="border"></div>
          <ul className="zastupstva-links">
            <li>
              <span>&#x2022;</span> U zračnom tunelu testirani su aerodinamični
              i stilski paketi
            </li>
            <li>
              <span>&#x2022;</span> Površine i dijelovi od karbonskih vlakana za
              tijelo i unutrašnjost
            </li>
            <li>
              <span>&#x2022;</span> Snažna poboljšanja performansi motora i
              sustavi dinamike vožnje
            </li>
            <li>
              <span>&#x2022;</span> Ispušni sustavi za zvuk koji oduzima dah
            </li>
            <li>
              <span>&#x2022;</span> Kotači od lakih legura s priključkom od 5
              rupa i središnjom bravom
            </li>
            <li>
              <span>&#x2022;</span> Unutarnja dorada i ručno izrađeni volani
            </li>
          </ul>
          <p>
            Aerodinamične i styling opcije TECHART naglašavaju sportsku
            orijentaciju individualiziranih Porscheovih vozila iz svakog kuta.
            Tipično TECHART: razlikovanje od serije je nepogrešivo. A opet
            toliko rezerviran da je zadržana Porscheova dizajnerska linija.
          </p>

          <div className="zastupstva-grid">
            <img
              src={Techart5}
              alt="TechArt"
              onClick={() => openImage(Techart5)}
            />
            <p>
              Izbor TECHART vanjskih opcija raznolik je. To uključuje cijele
              prednje i stražnje dijelove koji se neprimjetno uklapaju u oblik
              tijela. Prednji spojler i stražnje krilo koji na prvi pogled daju
              sportsku izjavu.
            </p>
          </div>

          <p>
            Popraćen gotovo neograničenim izborom komponenata karoserije
            TECHART, kao što su bočne suknje, poklopci motora, krovni spojleri,
            kao i vanjski dijelovi obloga i površinske obrade za serijske
            dijelove.
          </p>
          <Link to="https://www.techart.de/">https://www.techart.de/</Link>

          <div className="zastupstva-img-box"></div>
        </div>
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/brabus"
            className="btn"
            onClick={() => handleClick("/zastupstva/brabus")}
          >
            BRABUS &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/hamann"
            className="btn"
            onClick={() => handleClick("/zastupstva/hamann")}
          >
            HAMANN &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
