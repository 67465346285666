import { useState } from "react";
import { HashLink } from "react-router-hash-link";
// ----IMAGES---- //
// Sound Modul
import SoundModul1 from "../../../Images/usluge/Sound-modul2.JPG";
import SoundModul2 from "../../../Images/usluge/Panthera.JPG";
import SoundModul3 from "../../../Images/usluge/Sound-modul1.jpg";

// Tapeciranje
import Tapeciranje1 from "../../../Images/usluge/Tapeciranje.jpeg";
import Tapeciranje2 from "../../../Images/usluge/Tapeciranje-volana.jpg";
import Tapeciranje3 from "../../../Images/usluge/Tapeciranje3.jpeg";
import Tapeciranje4 from "../../../Images/usluge/Tapeciranje2.jpeg";
// Wrap
import Wrap from "../../../Images/usluge/Wrap-GT.jpg";
import WrapSCoupe from "../../../Images/usluge/Wrap-S-Coupe.jpg";
import WrapSmart from "../../../Images/usluge/Wrap-Smart.jpg";
// Ugradnja felgi
import UgradnjaFelgi1 from "../../../Images/usluge/Felge.jpg";
import UgradnjaFelgi2 from "../../../Images/usluge/AutoConnect-Felge.jpg";
import UgradnjaFelgi3 from "../../../Images/usluge/Felge2.jpg";
// Pojasevi u boji
import Pojasevi1 from "../../../Images/usluge/Pojasevi-crveni.jpg";
import Pojasevi2 from "../../../Images/usluge/Pojasevi-plavi.jpg";
import Pojasevi3 from "../../../Images/usluge/Pojasevi.jpg";
// Bojanje čeljusti
import BojanjeČeljusti1 from "../../../Images/usluge/Čeljusti2.jpg";
import BojanjeČeljusti2 from "../../../Images/usluge/Čeljusti.jpeg";
import BojanjeČeljusti3 from "../../../Images/usluge/Bojanje-čeljusti.jpg";
// Zatamnjivanje stakla
import ZatamnjivanjeStakla1 from "../../../Images/usluge/Stakla.jpg";
import ZatamnjivanjeStakla2 from "../../../Images/usluge/Stakla2.jpg";
import ZatamnjivanjeStakla3 from "../../../Images/usluge/Stakla3.JPG";

function UslugeBox({
  imageSrc,
  altText,
  title,
  description,
  description2,
  listItems,
  imageSrc1,
  imageSrc2,
  imageSrc3,
  alt1,
  alt2,
  alt3,
  link,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const toggleUslugeBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* **********USLUGE********** */}
      <div className="services-box">
        <img src={imageSrc} alt={altText} className="services-img" />
        <div className="services-title" onClick={toggleUslugeBox}>
          <h5>
            {title}
            <i className="fi fi-ss-angle-small-down open-usluge-box"></i>
          </h5>
        </div>
        {/* <!-- OPEN USLUGE BOX ON CLICK --> */}
        <div className={`usluge-box ${isOpen ? "open" : ""}`}>
          <p>{description}</p>
          <p>{description2}</p>
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>&#x2022; {item}</li>
            ))}
          </ul>
          <div className="usluge-img">
            <img
              src={imageSrc1}
              alt={alt1}
              onClick={() => openImage(imageSrc1)}
            />
            <img
              src={imageSrc2}
              alt={alt2}
              onClick={() => openImage(imageSrc2)}
            />
            <img
              src={imageSrc3}
              alt={alt3}
              onClick={() => openImage(imageSrc3)}
            />
          </div>
          <div className="usluge-btn">{link}</div>
        </div>
        {/*  <!-- ---- --> */}
      </div>

      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default function NaseUsluge() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="section section-usluge">
        <h1>NAŠE USLUGE</h1>
        <p>Napravite od svog ljubimca sportsku varijantu.</p>
        <p>
          Evo što vam još sve nudimo kako bi svome vozilu unaprijedili estetiku
          i kvalitetu. Iskustvo naših djelatnika i oprema našeg
          prodajno-servisnog centra jamče vrhunsku i visokokvalitetnu uslugu kod
          kupnje i servisiranja vašeg automobila.
        </p>
        <p>Trajanje i cijena variraju, ovisno o modelu automobila.</p>
        <div className="usluge-grid">
          {/* SOUND MODUL */}
          <UslugeBox
            imageSrc={SoundModul1}
            altText="Sound modul"
            title="SOUND MODUL"
            description="Želite li svom vozilu pružiti više osjećaja, ili Vaš standardni ispušni sustav ne proizvodi zvuk koji želite? Vozite li električno ili hibridno vozilo i možda uopće nemate buke u vožnji? Znamo koliko taj mali detalj može frustrirati i zato imamo jednostavno riješenje."
            description2="Pomoću aplikacija za pametni telefon Panthera Connect možete
          konfigurirati Leo Active Sound System kako god Vi želite.
          Dodirom gumba glasnoću, zvuk, brzinu, dodijeliti zvučni profil i
          aktivirati efekte."
            listItems={["Zvuk", "Ugradnja", "Bass"]}
            imageSrc1={SoundModul1}
            alt1="Sound modul"
            imageSrc2={SoundModul2}
            alt2="Sound modul"
            imageSrc3={SoundModul3}
            alt3="Sound modul"
            link={
              <HashLink
                smooth
                to="/sound-modul"
                onClick={() => handleClick("/sound-modul")}
              >
                Opširnije &rarr;
              </HashLink>
            }
          />
          {/* TAPECIRANJE */}
          <UslugeBox
            imageSrc={Tapeciranje1}
            altText="Tapeciranje"
            title="TAPECIRANJE"
            description="Kod tapeciranja koristimo tradiciju i modernu tehnologiju kako
            bismo podigli izgled i atmosferu unutar automobila na potpuno višu
            razinu. Osim klasičnog, modernog i sportskog dizajna nudimo
            restauraciju i personaliziranje, odnosno dizajn po vašoj želji."
            description2="Materijali koji Vam stoje na raspolaganju su koža, skaj, eko koža,
            alcantara, štof, velur i drugo."
            listItems={[
              "Djelomično ili kompletno tapeciranje sjedala",
              "Obnova konstrukcije sjedala",
              "Presvlačenje volana u kožu ili imitaciju kože",
              "Tapeciranje naslona za ruku",
              "Obnova konstrukcije sjedala",
            ]}
            imageSrc1={Tapeciranje2}
            alt1="Tapeciranje"
            imageSrc2={Tapeciranje3}
            alt2="Tapeciranje"
            imageSrc3={Tapeciranje4}
            alt3="Tapeciranje"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/* WRAP */}
          <UslugeBox
            imageSrc={WrapSCoupe}
            altText="Wrap"
            title="WRAP"
            description="Omatanje vozila nudi mnoge benefite. Možda želite zaštitu od
            vanjskih čimbenika ili promjeniti izgled vozila i pokazati svoju
            kreativnost. Imamo široku ponudu boja, tekstura i finiša uz koje
            Vaš automobil postaje unikat."
            description2="Prije svega važno je da se cijeli proces napravi profesionalno.
            Time dobivate dugotrajan učinak koji štiti Vaš auto u nadolazećim
            godinama."
            listItems={[
              "Promjena izgleda",
              "Zaštita površine vozila od UV zraka i štetnih utjecaja",
              "Obnova boje",
              "Širok izbor boja i efekata",
            ]}
            imageSrc1={Wrap}
            alt1="Wrap"
            imageSrc2={WrapSCoupe}
            alt2="Wrap"
            imageSrc3={WrapSmart}
            alt3="Wrap"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/* UGRADNJA FELGI */}
          <UslugeBox
            imageSrc={UgradnjaFelgi1}
            altText="Ugradnja felgi"
            title="UGRADNJA FELGI"
            description="Stručni smo u dobavljanju i ugradnji alu felgi. Između svih
            veličina, boja i uzoraka, savršen balans između stila i
            performanse pronaći ćete kod nas."
            description2="Širok asortiman je vrlo važan, jer su kotači vrlo specifičan i
            tehnički složen dio vozila i sigurnost je na prvom mjestu, zato se
            kod nas možete posavjetovati sa stručnjacima koji imaju godine
            iskustva u industriji."
            listItems={[
              "Razni brendovi u ponudi",
              "Montaža i zaštita felgi",
              "Ugradnja ventila",
            ]}
            imageSrc1={UgradnjaFelgi1}
            alt1="Ugradnja felgi"
            imageSrc2={UgradnjaFelgi2}
            alt2="Ugradnja felgi"
            imageSrc3={UgradnjaFelgi3}
            alt3="Ugradnja felgi"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/* POJASEVI U BOJI */}
          <UslugeBox
            imageSrc={Pojasevi1}
            altText="Pojasevi u boji"
            title="POJASEVI U BOJI"
            description="Bilo kakva boja miče monotoniju iz interijera vozila, tako da je u
            teško otići u krivome smjeru. U ponudi imamo sve boje i uzorke,
            ali samo najkvalitetnije materijale."
            description2="Uz uslugu tapeciranja i šavova u boji interijer može dati potpuno
            novo iskustvo putovanja."
            listItems={[]}
            imageSrc1={Pojasevi1}
            alt1="Pojasevi u boji"
            imageSrc2={Pojasevi2}
            alt2="Pojasevi u boji"
            imageSrc3={Pojasevi3}
            alt3="Pojasevi u boji"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/* BOJANJA ČELJUSTI */}
          <UslugeBox
            imageSrc={BojanjeČeljusti1}
            altText="Bojanje čeljusti"
            title="BOJANJA ČELJUSTI"
            description="Bilo kakva boja miče monotoniju iz interijera vozila, tako da je u
            teško otići u krivome smjeru. U ponudi imamo sve boje i uzorke,
            ali samo najkvalitetnije materijale."
            description2="Uz uslugu tapeciranja i šavova u boji interijer može dati potpuno
            novo iskustvo putovanja."
            listItems={[]}
            imageSrc1={UgradnjaFelgi1}
            alt1="Bojanje čeljusti"
            imageSrc2={BojanjeČeljusti2}
            alt2="Bojanje čeljusti"
            imageSrc3={BojanjeČeljusti3}
            alt3="Bojanje čeljustiap"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/* ZATAMNJIVANJE STAKLA */}
          <UslugeBox
            imageSrc={ZatamnjivanjeStakla1}
            altText="Zatamnjivanje stakla"
            title="ZATAMNJIVANJE STAKLA"
            description="Tražite li zaštitu od svjetla ili jednostavno suptilniju atmosferu
            na zadnjem sjedištu, važno je da tamljenje stakala obavi vješta
            ruka."
            description2="Zaštitna folija zaustavlja 99% štetnih zračenja, štiti putnike,
            interijer. Osim što prevenira rasprskavanje komadića stakala u
            slučaju nesreće i štiti od ozljeda, također podiže cijelokupan
            izgled vozila."
            listItems={[
              "Prednje vjetrobransko - 0%",
              "Prednja bočna - 30%",
              "Stražnja stakla - nema limita",
            ]}
            imageSrc1={ZatamnjivanjeStakla1}
            alt1="Zatamnjivanje stakla"
            imageSrc2={ZatamnjivanjeStakla3}
            alt2="Zatamnjivanje stakla"
            imageSrc3={ZatamnjivanjeStakla2}
            alt3="Zatamnjivanje stakla"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                Naruči se
              </HashLink>
            }
          />
          {/*  */}
        </div>
        <p>Kontaktirat ćemo Vas kako bismo potvrdili Vaš termin za uslugu.</p>
      </section>
    </>
  );
}
