import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";

// Images
import Hamann1 from "../../../Images/zastupstva/hamann/Hamann.png";
import Hamann2 from "../../../Images/zastupstva/hamann/Hamann2.png";
import Hamann3 from "../../../Images/zastupstva/hamann/Hamann3.png";
import Hamann4 from "../../../Images/zastupstva/hamann/Hamann4.png";
import Hamann5 from "../../../Images/zastupstva/hamann/Hamann5.png";

export default function Hamann() {
  useEffect(() => {
    document.title = "Autoconnect | Hamann";
  }, []);
  return (
    <>
      <Main imageUrl={Hamann3} altText="Brabus" />
      <HamannText />
    </>
  );
}

function HamannText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="section">
        <h2>HAMANN</h2>
        <div className="zastupstva-text">
          <p>
            HAMANN Motorsport nudi više od pukog podešavanja vozila: od 1986.
            godine naziv je razvoj i prilagodba vozila na najvišoj razini.
          </p>
          <p>
            Ispunite svoje automobilske snove i oslobodite se na stručnost i
            iskustvo HAMANN Motorsporta u dizajnerskim, kvalitativnim,
            performansama i načinu života.
          </p>
          <div className="zastupstva-grid">
            <img
              src={Hamann1}
              alt="Hamann"
              onClick={() => openImage(Hamann1)}
            />
            <img
              src={Hamann2}
              alt="Brabus"
              onClick={() => openImage(Hamann2)}
            />
          </div>

          <p>
            Najbolje podešavanje vozila-prepustite se inspiraciji Dajte se
            nadahnuti – našim ekskluzivnim programima za vrhunske modele marke
            BMW , Range Rover , Lamborghini, Mercedes–Benz i Porsche , posebno
            Macan i Cayenne. Ugađanje vozila na najvišoj razini!
          </p>
        </div>

        <div className="zastupstva-text">
          <h5>EKSKLUZIVNO HAMANNOVO UGAĐANJE ZA VRLO POSEBNE AUTOMOBILE</h5>

          <p>
            Ako tražite nešto posebno, individualno i jedinstveno, zasigurno
            ćete ga pronaći na HAMANN Motorsport. Program ugađanja uključuje
            moćne koncepte širokog karoserije i sportske aerodinamične komplete
            s mnogim stvarnim karbonskim elementima, optimizacije šasije
            orijentirane na performanse, visokokvalitetne lagane aluminijske
            naplatke i poboljšanja performansi i još mnogo toga.
          </p>
          <div className="zastupstva-grid">
            <div>
              <img
                src={Hamann4}
                alt="Hamann"
                onClick={() => openImage(Hamann4)}
              />
              <img
                src={Hamann5}
                alt="Hamann"
                onClick={() => openImage(Hamann5)}
              />
            </div>
          </div>
          <p>
            Hamann Tuning također nudi nešto posebno s komponentama za Panameru,
            Cayenne ili 911 (997). Hamannovi razvoji za Mercedes-Benz G-modele
            G55, G63 i G65, kao i SLS AMG R197 i SLS AMG C197 također su
            vrijedne pažnje. Također i marka Lamborghini tim Hamanna prihvaćen
            je konceptom individualizacije i visokokvalitetnih komponenata za
            Aventador, Gallardo, LP 640 ili Murciélago. HAMANN Motorsport
            također čini automobilsku luksuznu klasu nepogrešivim – bez obzira
            na to što su Rolls Royce Wraithu, Drophead Coupéu, Phantomu ili
            Ghostu ili Bentleyu Continental GT. Kupci u cijelom svijetu vjeruju
            u kompetentnost HAMANN Motorsporta – mogućnosti prilagodbe ne
            postoje, Hamannov tim također omogućava nemoguće.
          </p>
          <Link to="https://www.hamann-motorsport.com/">
            https://www.hamann-motorsport.com/
          </Link>

          <div className="zastupstva-img-box"></div>
        </div>
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/brabus"
            className="btn"
            onClick={() => handleClick("/zastupstva/brabus")}
          >
            BRABUS &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/techart"
            className="btn"
            onClick={() => handleClick("/zastupstva/techart")}
          >
            TECHART &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
