import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KontaktBackground from "../../../Images/galerija-auta/galerija-auta9.jpg";
import Main from "../Main/Main";

export default function Kontakt() {
  useEffect(() => {
    document.title = "Autoconnect | Kontakt";
  }, []);
  return (
    <>
      <Main imageUrl={KontaktBackground} altText="Kontakt" />
      <KontaktInfo />
      <div className="border"></div>
      <KontaktMap />
      <div className="border"></div>
      <KontaktForm />
    </>
  );
}

function KontaktInfo() {
  return (
    <>
      <section className="section">
        <h1>KONTAKT</h1>

        <div className="contact-grid">
          {/*  <!--  --> */}
          <Link
            to="https://goo.gl/maps/mPQx81L7aahvm7B4A"
            target="_blank"
            className="contact-box"
          >
            <i className="fi fi-ss-marker"></i>
            <p>Zagrebačka 33, 10 360 Sesvete</p>
          </Link>

          {/* <!--  --> */}
          <a href="tel:+385 1 2911 500" className="contact-box">
            <i className="fi fi-ss-phone-call"></i>
            <p>Tel:+385 1 2911 500</p>
          </a>
          {/* <!--  --> */}
          <a href="tel:+385 1 2911 430" className="contact-box">
            <i className="fi fi-ss-phone-office"></i>
            <p>Fax:+385 1 2911 430</p>
          </a>
          {/* <!--  --> */}
        </div>
        <div className="contact-worktime">
          <i className="fi fi-ss-clock-three"></i>
          <h4>Pon-Pet</h4>
          <p>08:00 - 16:00</p>
        </div>
      </section>
    </>
  );
}

function KontaktMap() {
  return (
    <>
      <section className="section">
        <iframe
          title="Autoconnect location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.164110075327!2d16.101809199999998!3d45.82799659999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476679d2d75c66cf%3A0x2d8ac8ee2c99bd83!2sZagreba%C4%8Dka%20cesta%2033%2C%2010360%2C%20Sesvete!5e0!3m2!1shr!2shr!4v1689865089017!5m2!1shr!2shr"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </section>
    </>
  );
}

function KontaktForm() {
  const [isMessageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Your existing form data
    const formData = new FormData(event.target);

    try {
      // Submit the form data to the web3forms API
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      // Check if the form submission was successful
      if (response.ok) {
        setMessageSent(true);
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <section className="section">
        <h2>KONTAKTIRAJTE NAS</h2>
        {!isMessageSent ? (
          <form onSubmit={handleSubmit} className="contact-form" id="form">
            {/* <!-- Key --> */}
            <input
              type="hidden"
              name="access_key"
              value="ad815d2a-19f1-4774-9798-5d3dd2b1be90"
            />

            {/* <!-- From name --> */}
            <input type="hidden" name="from_name" value="Info AutoConnect" />
            {/* <!-- Subject name --> */}
            <input type="hidden" name="subject" value="Novi upit" />

            <label htmlFor="name">Ime i prezime:</label>
            <input
              type="text"
              name="Ime i Prezime"
              placeholder="Ime i Prezime"
              id="name"
              autoComplete="name"
            />
            <label htmlFor="email">
              Email: <span>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="name"
              placeholder="primjer@gmail.com"
              required
            />
            <label htmlFor="phone">Mobitel:</label>
            <input
              type="tel"
              name="Broj mobitela"
              id="phone"
              autoComplete="name"
              placeholder="+385 1 234 5678"
            />

            <label htmlFor="message">
              Pošaljite poruku: <span>*</span>
            </label>
            <textarea
              name="Poruka"
              id="message"
              placeholder="Vaša poruka..."
              required
            ></textarea>

            {/* <!-- Honeypot --> */}
            <input
              type="checkbox"
              name="botcheck"
              className="hidden"
              style={{ display: "none" }}
            />
            <input type="submit" value="POŠALJI" id="submit-btn" />
          </form>
        ) : (
          <div className="thank-you">
            <h1>
              PORUKA POSLANA! <i className="fi fi-ss-check-circle"></i>
            </h1>
            <p>Kontaktirati ćemo Vas u najkraćem mogućem roku!</p>
          </div>
        )}
        {error && (
          <div className="error-box">
            <h1>
              DOŠLO JE DO POGREŠKE! <i class="fi fi-ss-exclamation"></i>
            </h1>
            <p>Pokušajte ponovo kasnije ili nas nazovite </p>
            <p>
              <a href="tel:+385 1 2911 500">
                <p>
                  <i className="fi fi-ss-phone-call"></i> +385 1 2911 500
                </p>
              </a>
            </p>
          </div>
        )}
      </section>
    </>
  );
}
