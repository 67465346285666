import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const hideFooter = ["/hvala"];
  let date = new Date().getFullYear();

  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };
  // Hide footer if location is /hvala
  if (hideFooter.includes(location.pathname)) {
    return <></>;
  }

  return (
    <>
      <footer>
        <div className="footer-grid">
          <div className="footer-box">
            <h2>
              Autoconnect <span>d.o.o.</span>
            </h2>
            <Link to="https://goo.gl/maps/mPQx81L7aahvm7B4A" target="_blank">
              Zagrebačka 33, 10 360 Sesvete
            </Link>

            <p>Hrvatska</p>
            <p>OIB: 27909788832</p>
            <p>MB: 05259355</p>
          </div>

          <div className="footer-box">
            <h2>KONTAKTI</h2>
            <a href="tel:+385 1 2911 500">Tel: +385 1 2911 500</a>
            <a href="tel:+385 1 2911 430">Fax: +385 1 2911 430</a>
            <a href="mailto:info@autoconnect.hr">Email: info@autoconnect.hr</a>
          </div>

          <div className="footer-box">
            <h2>PODACI O TVRTKI</h2>
            <p>Sud upisa u registar: Trgovački sud u Zagrebu</p>
            <p>IBAN: HR2023400091111070655</p>
            <p>otvoren u Privredna banka Zagreb d.d.</p>
            <p>Temeljni kapital: 20.000,00 kn, uplaćen u cijelosti</p>
          </div>

          <div className="footer-box">
            <h2>LINKOVI</h2>
            <Link to="/opci-uvjeti" onClick={() => handleClick("/")}>
              Opći uvijeti poslovanja
            </Link>
            <Link to="/politika-privatnosti" onClick={() => handleClick("/")}>
              Politika privatnosti
            </Link>
          </div>
        </div>

        <div className="footer-icons">
          <a href="https://hr-hr.facebook.com/people/AutoConnect-Brabus/pfbid0TGErZA6N6MC9bXzBNKqM7RqSzavhzcmfvTw3eMGqXiAXrPLv1BdsvWfJVCHiyCQZl/">
            <i className="fi fi-brands-facebook"></i>
          </a>
          <a href="https://www.instagram.com/brabus_hrvatska/">
            <i className="fi fi-brands-instagram"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCNK1_OAsDyRFFmus7FrXwbA">
            <i className="fi fi-brands-youtube"></i>
          </a>
          <p>
            &copy; Copyright{" "}
            <span id="year">{date} Autoconnect. All rights reserved.</span>
          </p>
        </div>
      </footer>
    </>
  );
}
