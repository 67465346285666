// Images
import GalerijaAuta1 from "../../../Images/galerija-auta/galerija-auta1.jpg";
import GalerijaAuta2 from "../../../Images/galerija-auta/galerija-auta2.jpg";
import GalerijaAuta3 from "../../../Images/galerija-auta/galerija-auta3.jpg";
import GalerijaAuta4 from "../../../Images/galerija-auta/galerija-auta4.jpg";
import GalerijaAuta5 from "../../../Images/galerija-auta/galerija-auta5.jpg";
import GalerijaAuta6 from "../../../Images/galerija-auta/galerija-auta6.jpg";
import GalerijaAuta7 from "../../../Images/galerija-auta/galerija-auta7.jpg";
import GalerijaAuta8 from "../../../Images/galerija-auta/galerija-auta8.jpg";
import GalerijaAuta9 from "../../../Images/galerija-auta/galerija-auta9.jpg";
import GalerijaAuta10 from "../../../Images/galerija-auta/galerija-auta10.jpg";
import GalerijaAuta11 from "../../../Images/galerija-auta/galerija-auta11.jpg";
import GalerijaAuta12 from "../../../Images/galerija-auta/galerija-auta12.jpg";
import GalerijaAuta13 from "../../../Images/galerija-auta/galerija-auta13.jpg";
import GalerijaAuta14 from "../../../Images/galerija-auta/galerija-auta14.jpg";
import GalerijaAuta15 from "../../../Images/galerija-auta/galerija-auta15.jpg";
import GalerijaAuta16 from "../../../Images/galerija-auta/galerija-auta16.jpg";
// useState
import { useState } from "react";

export default function ProjectGallery() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  return (
    <>
      <section className="section">
        <h2>CAR GALLERY</h2>

        <div className="grid-4">
          <img
            src={GalerijaAuta1}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta1)}
          />
          {/*  */}
          <img
            src={GalerijaAuta2}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta2)}
          />
          {/*  */}
          <img
            src={GalerijaAuta3}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta3)}
          />
          {/*  */}
          <img
            src={GalerijaAuta4}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta4)}
          />
          {/*  */}
          <img
            src={GalerijaAuta5}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta5)}
          />
          {/*  */}
          <img
            src={GalerijaAuta6}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta6)}
          />
          {/*  */}
          <img
            src={GalerijaAuta7}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta7)}
          />
          {/*  */}
          <img
            src={GalerijaAuta8}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta8)}
          />
          {/*  */}
          <img
            src={GalerijaAuta9}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta9)}
          />
          {/*  */}
          <img
            src={GalerijaAuta10}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta10)}
          />
          {/*  */}
          <img
            src={GalerijaAuta11}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta11)}
          />

          {/*  */}
          <img
            src={GalerijaAuta12}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta12)}
          />
          {/*  */}
          <img
            src={GalerijaAuta13}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta13)}
          />
          {/*  */}
          <img
            src={GalerijaAuta14}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta14)}
          />
          {/*  */}
          <img
            src={GalerijaAuta15}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta15)}
          />
          {/*  */}
          <img
            src={GalerijaAuta16}
            alt="Car gallery"
            className="gallery-img"
            onClick={() => openImage(GalerijaAuta16)}
          />
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
